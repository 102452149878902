import "@css/app.css";

/* JS */
import "lazysizes";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

Fancybox.bind("[data-fancybox]", {});

window.disableBodyScroll = disableBodyScroll;
window.enableBodyScroll = enableBodyScroll;

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";

window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.start();

const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty("--app-height", `${window.innerHeight}px`);
};
window.addEventListener("resize", appHeight);
appHeight();

window.appHeight = appHeight;

class InlineVideo {
  constructor(el) {
    this._el = el;
    this._video = el.dataset.video;
    this._image = el.querySelector(".js-video__placeholder");

    this._el.addEventListener("click", this.handleClick.bind(this));
  }

  handleClick() {
    this._el.insertAdjacentHTML(
      "beforeend",
      `<iframe src="${this._video}?autoplay=1&rel=0" frameborder="0"></iframe>`
    );
    this._image.classList.add("fade-out");

    setTimeout(() => {
      this._image.remove();
    }, 700);

    this._el.removeEventListener("click", this.handleClick);
  }
}

Array.from(document.querySelectorAll(".js-video")).forEach((video) => {
  new InlineVideo(video);
});

const CURRENT_USER_ID = parseInt(
  document.querySelector('meta[name="user_id"]').getAttribute("content")
);
const CSRF_TOKEN = document
  .querySelector('meta[name="CRAFT_CSRF_TOKEN"]')
  .getAttribute("content");

Array.from(document.querySelectorAll(".js-search"), (form) => {
  form.addEventListener("submit", (e) => {
    event.preventDefault();
  });

  const searchInput = form.querySelector('input[type="search"]');
  let searchTimeout = null;
  let resultsContainer = form.querySelector(".results-container");

  searchInput.addEventListener("input", () => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(async () => {
      const query = searchInput.value;
      if (query.length == 0) {
        resultsContainer.innerHTML = "";
        return;
      }
      const results = await fetchResults(query);
      resultsContainer.innerHTML = "";
      resultsContainer.appendChild(results);
    }, 150);
  });
});

async function fetchResults(query) {
  const response = await fetch(`/search?q=${query}`);
  const body = await response.text();
  const parser = new DOMParser();
  const doc = parser.parseFromString(body, "text/html");
  const results = doc.querySelector(".results");
  return results;
}

let imageContainer = document.querySelector(".js-rotate");
const DURATION = 7 * 1000;
if (imageContainer) {
  const images = document.querySelectorAll(".js-rotate .js-image");
  var num = images.length;
  var index = 1;

  setInterval(function () {
    // $("#testimonials p").text(testimonial[index]);
    images[index].classList.add("active");
    setTimeout(() => {
      images[index].classList.remove("active");
    }, DURATION);
    index = (index + 1) % num;
  }, DURATION);
}
